.box {
  padding-top: 5px;
  padding-right: 15px;
  padding-bottom: 7px;
  padding-left: 15px;
  transform: translate(-50%, 0);
  position: absolute;
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  /* line-height: 39px; */
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  /* width: fit-content; */
  /* height: fit-content; */
  background: #5a2020;
  border: 1px solid #b30000;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 8px;
  position: absolute;

  top: -30px;
  left: 50%;
  z-index: 5;

  transition: top 0.5s ease;
}

.box_active {
  top: 10px;
}
