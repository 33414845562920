.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* background: #000000; */

  height: 100%;
  width: 100%;
  position: absolute;
}

.background {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}

.logo {
  height: 50px;
  position: absolute;
  right: 17px;
  bottom: 10px;
}

.socitylogo {
  width: 173px;
  height: 173px;
  left: 10%;

  mix-blend-mode: luminosity;
  border-radius: 96px;

  margin-left: 40px;
}

.rowGroup {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.titleGroup {
  margin-bottom: 65px;
}

.vcenter {
  width: 100%;
  position: absolute;
  top: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  left: 15%;
  margin-left: 40px;

  width: 620px;
  height: 205px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 96px;
  line-height: 96px;
  /* or 100% */

  display: flex;
  align-items: center;

  color: #ffffff;
}

.subtitle {
  margin-top: 11px;
  position: relative;

  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}

.description {
  margin-top: 46px;
  position: relative;

  width: 1004px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;

  color: #ffffff;
}

.button {
  margin-left: 253px;

  min-width: 200px;
  min-height: 60px;
}

.pagination {
  margin-top: 120px;
}
