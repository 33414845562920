.button {
  border-radius: 16px;

  transition: color 0.7s, background-color, border 0.7s;
  align-items: center;
  cursor: pointer;
}

.largetext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.smalltext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
}

.button_0 {
  color: white;
  background: transparent;
  border: 3px solid white;
}

.button_0:hover {
  color: black;
  background: white;
  /* border: 3px solid black; */
}

.button_1 {
  color: #566a44;
  background: transparent;
  border: 3px solid #566a44;
}

.button_1:hover {
  color: white;
  background: #566a44;
  /* border: 3px solid black; */
}

.button_2 {
  color: #ba77a8;
  background: transparent;
  border: 3px solid #ba77a8;
}

.button_2:hover {
  color: white;
  background: #ba77a8;
  /* border: 3px solid black; */
}

.button_3 {
  color: #873671;
  background: transparent;
  border: 3px solid #873671;
}

.button_3:hover {
  color: white;
  background: #873671;
  /* border: 3px solid black; */
}

.button_4 {
  color: #6c1254;
  background: transparent;
  border: 3px solid #6c1254;
}

.button_4:hover {
  color: white;
  background: #6c1254;
  /* border: 3px solid black; */
}

.button_5 {
  color: #c09b9f;
  background: transparent;
  border: 3px solid #c09b9f;
}

.button_5:hover {
  color: white;
  background: #c09b9f;
  /* border: 3px solid black; */
}

.button_6 {
  color: #b46a71;
  background: transparent;
  border: 3px solid #b46a71;
}

.button_6:hover {
  color: white;
  background: #b46a71;
  /* border: 3px solid black; */
}

.button_7 {
  color: #923740;
  background: transparent;
  border: 3px solid #923740;
}

.button_7:hover {
  color: white;
  background: #923740;
  /* border: 3px solid black; */
}

.button_8 {
  color: #fadabd;
  background: transparent;
  border: 3px solid #fadabd;
}

.button_8:hover {
  color: white;
  background: #fadabd;
  /* border: 3px solid black; */
}

.button_9 {
  color: #dfb187;
  background: transparent;
  border: 3px solid #dfb187;
}

.button_9:hover {
  color: white;
  background: #dfb187;
  /* border: 3px solid black; */
}

.button_10 {
  color: #bc8b5e;
  background: transparent;
  border: 3px solid #bc8b5e;
}

.button_10:hover {
  color: white;
  background: #bc8b5e;
  /* border: 3px solid black; */
}

.button_11 {
  color: #fbf0d3;
  background: transparent;
  border: 3px solid #fbf0d3;
}

.button_11:hover {
  color: white;
  background: #efdba6;
  /* border: 3px solid black; */
}

.button_12 {
  color: #efdba6;
  background: transparent;
  border: 3px solid #efdba6;
}

.button_12:hover {
  color: white;
  background: #efdba6;
  /* border: 3px solid black; */
}

.button_13 {
  color: #c38173;
  background: transparent;
  border: 3px solid #c38173;
}

.button_13:hover {
  color: white;
  background: #c38173;
  /* border: 3px solid black; */
}

.button_14 {
  color: #f29383;
  background: transparent;
  border: 3px solid #f29383;
}

.button_14:hover {
  color: white;
  background: #f29383;
  /* border: 3px solid black; */
}

.button_15 {
  color: #d96149;
  background: transparent;
  border: 3px solid #d96149;
}

.button_15:hover {
  color: white;
  background: #d96149;
  /* border: 3px solid black; */
}

.button_16 {
  color: #d8d8d8;
  background: transparent;
  border: 3px solid #d8d8d8;
}

.button_16:hover {
  color: white;
  background: #d8d8d8;
  /* border: 3px solid black; */
}

.button_17 {
  color: #8b9bea;
  background: transparent;
  border: 3px solid #8b9bea;
}

.button_17:hover {
  color: white;
  background: #8b9bea;
  /* border: 3px solid black; */
}

.button_18 {
  color: #7783c5;
  background: transparent;
  border: 3px solid #7783c5;
}

.button_18:hover {
  color: white;
  background: #7783c5;
  /* border: 3px solid black; */
}

.button_19 {
  color: #555f93;
  background: transparent;
  border: 3px solid #555f93;
}

.button_19:hover {
  color: white;
  background: #555f93;
  /* border: 3px solid black; */
}

.button_20 {
  color: #b9b5de;
  background: transparent;
  border: 3px solid #b9b5de;
}

.button_20:hover {
  color: white;
  background: #b9b5de;
  /* border: 3px solid black; */
}

.button_21 {
  color: #7e78b1;
  background: transparent;
  border: 3px solid #7e78b1;
}

.button_21:hover {
  color: white;
  background: #7e78b1;
  /* border: 3px solid black; */
}

.button_22 {
  color: #46407c;
  background: transparent;
  border: 3px solid #46407c;
}

.button_22:hover {
  color: white;
  background: #46407c;
  /* border: 3px solid black; */
}

.button_23 {
  color: #7ab3dc;
  background: transparent;
  border: 3px solid #7ab3dc;
}

.button_23:hover {
  color: white;
  background: #7ab3dc;
  /* border: 3px solid black; */
}

.button_24 {
  color: #568fb8;
  background: transparent;
  border: 3px solid #568fb8;
}

.button_24:hover {
  color: white;
  background: #568fb8;
  /* border: 3px solid black; */
}

.button_25 {
  color: white;
  background: #121212;
  border: 1px solid white;

  border: 1px solid #2c2c2c;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 8px;
}

.button_25:hover {
  color: #121212;
  background: white;
  /* border: 3px solid black; */
}

.button_26 {
  color: white;
  background: #2c2c2c;
  border: 1px solid 2c2c2c;

  border: 1px solid #2c2c2c;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 8px;

  cursor: not-allowed;
}

.button_disable {
  color: #4d4d4d;
  background: transparent;
  border: 3px solid #4d4d4d;

  cursor: not-allowed;
}
