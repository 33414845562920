.visualization {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.mapProtectionWrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.containerFluid {
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.rowGroup {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.h100 {
  height: 100% !important;
}

.w100 {
  width: 100% !important;
}

.col3 {
  flex: 0 0 20%;
}

.panelCol {
  width: 20%;
  min-width: 350px;
  max-width: 350px;
  overflow-y: auto;

  padding-left: 16px;
  padding-right: 16px;
  pointer-events: auto;

  padding-bottom: 45px;

  overflow: inherit;
}

.outputCol {
  z-index: 2;

  /* width: 20%;
  min-width: 350px; */
  min-width: 400px;
  max-width: 400px;

  padding-left: 16px;
  padding-right: 16px;
}

.col {
  flex-basis: 0;
  /* flex-grow: 1; */
  flex-grow: 0;
  max-width: 100%;
}

.titleBox {
  box-sizing: border-box;

  height: 145px;
  min-height: 145px;
  margin-top: 25px;

  z-index: 2;

  background: #121212;
  border: 1px solid #2c2c2c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  pointer-events: auto;
}

.siteBox {
  box-sizing: border-box;

  margin-top: 25px;

  z-index: 2;

  background: #121212;
  border: 1px solid #2c2c2c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  pointer-events: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.votingBox {
  box-sizing: border-box;

  margin-top: 25px;

  z-index: 2;

  background: #121212;
  border: 1px solid #2c2c2c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  pointer-events: auto;
  padding-left: 10px;
}

.contentTextSmall {
  margin-right: 14px;
  margin-left: 14px;
  margin-top: 20px;
  margin-bottom: 30px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;

  display: flex;
  align-items: center;

  color: #ffffff;

  word-break: break-word;
}

.ind_contentTextSmall {
  margin-right: 14px;
  margin-left: 14px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.ind_titleTextSmall {
  margin-left: 14px;
  margin-top: 20px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  /* identical to box height, or 180% */

  display: flex;
  align-items: center;

  color: #ffffff;
}

.ind_titleTextMiddle {
  margin-right: 14px;
  margin-left: 14px;
  margin-top: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  /* identical to box height, or 225% */

  display: flex;
  align-items: center;

  color: #ea4c6f;
}

.DecoLine {
  position: absolute;
  margin-left: 15px;
  margin-top: 15px;
}

.titleTextSmall {
  margin-left: 14px;
  margin-top: 20px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;

  display: flex;
  align-items: center;

  color: #ffffff;
}

.titleTextMiddle {
  margin-right: 14px;
  margin-left: 14px;
  margin-top: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;

  display: flex;
  align-items: center;

  color: #ffffff;
}

.titleTextLarge {
  margin-right: 14px;
  margin-left: 14px;
  margin-top: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  /* or 150% */

  display: flex;
  align-items: center;

  color: #ffffff;
}

.backBox {
  display: flex;
  margin-top: 25px;
  width: fit-content;
  cursor: pointer;
}

.backButton {
  margin-left: 14px;
}

.backText {
  margin-left: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;

  display: flex;
  align-items: center;

  color: #ffffff;
}

.backText:hover {
  text-decoration: underline;
}

.button {
  pointer-events: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 90%;
  height: 47.25px;
  margin-left: 5%;
}

.buttonGroup {
  overflow-y: scroll;
  overflow-x: hidden;

  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;

  padding: 2px;
}

.inbox_button {
  margin-bottom: 20px;
  width: 90%;
  height: 47.25px;
  margin-left: 5%;
}

.radar {
  width: 100%;
  height: 300px;
  margin-top: 55px;
  margin-bottom: 20px;
}

.bar {
  width: 100%;
  height: 190px;
  margin-top: 30px;
}



.costText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin-left: 7px;
  color: white;
  width: 80px;
}

.legend_1 {
  position: absolute;
  margin-left: 83px;
  margin-top: 35px;

  width: 32px;
  height: 10px;

  background: rgba(234, 76, 111, 0.2);
  border: 1px solid #ea4c6f;
}

.legend_2 {
  position: absolute;
  margin-left: 187px;
  margin-top: 35px;

  width: 32px;
  height: 10px;

  background: rgba(36, 83, 255, 0.2);
  border: 1px solid #2453ff;
}

.legend_1_text {
  position: absolute;
  margin-left: 26px;
  margin-top: 35px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;
  /* identical to box height, or 83% */

  display: flex;
  align-items: center;

  color: #888888;
}

.legend_2_text {
  position: absolute;
  margin-left: 134px;
  margin-top: 35px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;
  /* identical to box height, or 83% */

  display: flex;
  align-items: center;

  color: #888888;
}

.sliderBox {
  margin-left: 5%;
  width: 300px;
  /* margin-top: 20px; */

  text-align: justify;

  color: #ffffff;
}

.sliderItem {
  margin-top: 15px;
  margin-left: 5%;
  width: 90%;
  margin-bottom: 10px;
}

.sliderItemText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;

  display: flex;
  align-items: center;

  color: #ffffff;

  margin-top: 20px;
}

.sliderItemText:hover {
  text-decoration: underline;
}

.amen_button {
  /* margin-bottom: 20px; */
  min-width: 200px;
  min-height: 40px;
  margin-left: 15px;
}

.amen_list {
  width: 100%;
  max-height: 400px;
  overflow: auto;
  margin-bottom: 30px;
}

.logo {
  z-index: 1;
  height: 50px;
  position: absolute;
  right: 17px;
  bottom: 10px;
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 0px;
}

.panelCol ::-webkit-scrollbar {
  width: 9px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
  /* border-radius: 7px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: rgb(127, 255, 0); */
  background: black;
  outline: 2px solid white;
}

.scroll_layer {
  min-width: 380px;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: auto;
}
