:root {
  --zoom-factor: 1;
}

/* Definitions */
@font-face {
  font-family: "Inter";
  src: url(./fonts/Inter-VariableFont_slnt_wght.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  position: absolute;
  background: #000000;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mapboxgl-ctrl-bottom-left {
  display: none;
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

/* zoom out */
@media screen and (min-height: 1300px) {
  :root {
    --zoom-factor: 1.1;
  }
}

@media screen and (min-width: 2200px) {
  :root {
    --zoom-factor: 1.1;
  }
}

@media screen and (min-height: 1500px) {
  :root {
    --zoom-factor: 1.2;
  }
}

@media screen and (min-width: 2400px) {
  :root {
    --zoom-factor: 1.2;
  }
}

@media screen and (min-height: 1700px) {
  :root {
    --zoom-factor: 1.33;
  }
}

@media screen and (min-width: 2550px) {
  :root {
    --zoom-factor: 1.33;
  }
}

@media screen and (min-height: 1900px) {
  :root {
    --zoom-factor: 1.6;
  }
}

@media screen and (min-width: 3000px) {
  :root {
    --zoom-factor: 1.6;
  }
}

/* zoom in */
@media screen and (max-width: 1400px) {
  :root {
    --zoom-factor: 0.8;
  }
}
@media screen and (max-width: 1100px) {
  :root {
    --zoom-factor: 0.6;
  }
}

@media screen and (max-height: 900px) {
  :root {
    --zoom-factor: 0.8;
  }
}

@media screen and (max-height: 800px) {
  :root {
    --zoom-factor: 0.7;
  }
}

@media screen and (max-height: 700px) {
  :root {
    --zoom-factor: 0.5;
  }
}

@media screen and (max-height: 600px) {
  :root {
    --zoom-factor: 0.4;
  }
}

@media screen and (max-height: 500px) {
  :root {
    --zoom-factor: 0.3;
  }
}

html {
  zoom: var(--zoom-factor);
}
#protected_ul {
  zoom: calc(1 / var(--zoom-factor));
  transform: scale(var(--zoom-factor));
  transform-origin: 0 0;
}
#protected_ur {
  zoom: calc(1 / var(--zoom-factor));
  transform: scale(var(--zoom-factor));
  transform-origin: 100% 0;
}
#protected_c {
  zoom: calc(1 / var(--zoom-factor));
  transform: translate(-50%, -50%) scale(var(--zoom-factor));
}
#protected_map {
  zoom: calc(1 / var(--zoom-factor));
  transform: scale(1);
}
