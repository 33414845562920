.logo {
  max-height: 50px;
  max-width: 80%;
  aspect-ratio: 46 / 5;
  position: absolute;
  right: 17px;
  bottom: 10px;
}

.text {
  top: 30%;
  left: 50%;
  transform: translate(-50%, 0);

  position: absolute;

  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}
