.container {
  width: 432px;
  height: calc(100% - 2px);
  min-height: 950px;

  position: absolute;
  top: 0px;
  left: -390px;

  transition: left 0.5s;
  transition-timing-function: ease-in-out;

  background: #121212;
  border: 1px solid #2c2c2c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  z-index: 1;
  pointer-events: auto;
  /* overflow: auto; */
}

.container:hover {
  /* left: 0px; */
  left: -40px;
}

.title {
  margin-right: 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  /* identical to box height, or 400% */

  text-align: right;

  color: #5b5b5b;
}

.title_active {
  margin-right: 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  /* identical to box height, or 400% */

  text-align: right;

  color: #ffffff;
  cursor: pointer;
}

.subtitle {
  margin-right: 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  /* identical to box height, or 400% */

  text-align: right;

  color: #5b5b5b;
}

.subtitle_active {
  margin-right: 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  /* identical to box height, or 400% */

  text-align: right;

  color: #ffffff;
  cursor: pointer;
}

.title_active:hover {
  text-decoration: underline;
}

.subtitle_active:hover {
  text-decoration: underline;
}

.circle_transition {
  transition: fill 0.5s ease;
}

.circle {
  margin-right: 23px;
  transform: translate(50%, 0);

  color: #5b5b5b;
}

.circle_active {
  margin-right: 23px;
  transform: translate(50%, 0);

  color: #ffffff;
  cursor: pointer;
}

.rowGroup {
  height: 21.6px;
  margin-top: 22px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.chapter {
  margin-top: 56px;
}

.sandbox {
  margin-top: 56px;
  position: absolute;
  bottom: 60px;
  width: 100%;
}

.scrollCol {
  position: absolute;
  height: 100%;
  width: 450px;
  overflow: auto;
  overflow-x: hidden;
  /* pointer-events: none; */
  pointer-events: auto;
}

.decoLine_1 {
  position: absolute;
  right: 22px;
  top: 65px;
}

.decoLine_2 {
  position: absolute;
  right: 22px;
  top: 320px;
}

.decoLine_3 {
  position: absolute;
  right: 22px;
  top: 575px;
}

/* ::-webkit-scrollbar {
  width: 0px;
} */
