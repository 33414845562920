.container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  /* background: #000000; */

  /* height: 100%;
  width: 100%; */
}

.button {
  position: absolute;
  right: 64px;
  bottom: 76px;

  min-width: 200px;
  min-height: 60px;
}

.rowGroup {
  display: flex;
  flex-direction: row;
  width: 1248px;
  /* margin-top: 92px; */
}

.hint {
  position: absolute;
  right: 294px;
  bottom: 86px;

  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  /* identical to box height, or 180% */

  text-align: right;

  color: #ffffff;
}

.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  /* identical to box height, or 112% */

  color: #ffffff;
}

.description {
  width: 40%;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  /* or 125% */

  text-align: justify;

  color: #ffffff;
}

.slider {
    margin-left: 5%;
    width: 55%;
  
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    /* or 125% */
  
    text-align: justify;
  
    color: #ffffff;
  }
