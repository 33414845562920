.sliderBox {
    margin-left: 5%;
    width: 100%;
    /* margin-top: 20px; */
  
    text-align: justify;
  
    color: #ffffff;
  }
  
  .sliderItem {
    margin-top: 15px;
    margin-left: 5%;
    width: 90%;
    margin-bottom: 10px;
  }
  
  .sliderItemText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
  
    display: flex;
    align-items: center;
  
    color: #ffffff;
  
    margin-top: 20px;
  }
  
  .sliderItemText:hover {
    text-decoration: underline;
  }
  