.container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  /* background: #000000; */

  width: 1248px;
  height: 756px;
}

.button {
  position: absolute;
  right: 64px;
  bottom: 76px;

  min-width: 200px;
  min-height: 60px;
}

.rowGroup {
  display: flex;
  flex-direction: row;
  width: 50%;
}

.buttonRow {
  margin-top: 25px;

  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.amen_button {
  /* margin-bottom: 20px; */
  min-width: 200px;
  min-height: 40px;
  margin-left: 15px;
}

.costText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin-left: 7px;
  color: white;
  width: 80px;
}

.hint {
  position: absolute;
  right: 294px;
  bottom: 86px;

  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  /* identical to box height, or 180% */

  text-align: right;

  color: #ffffff;
}

.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  /* identical to box height, or 112% */

  color: #ffffff;
}

.description {
  /* width: 80%; */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  /* or 125% */

  text-align: justify;

  color: #ffffff;
}

.slider {
  margin-left: 5%;
  width: 55%;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  /* or 125% */

  text-align: justify;

  color: #ffffff;
}

.amen_button {
  min-width: 190px;
  min-height: 55px;
}

.amen_list {
  margin-right: 40px;
  /* margin-left: 20px; */
  margin-top: 15px;
  width: 50%;
  height: 100%;
}
