.blurClass {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
}

.box {
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: 1px solid;
  border-radius: 5px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  /* background-color: white;
  color: black; */

  background: #121212;
  border: 1px solid #2c2c2c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.box > div {
  padding: 10px;
}

.boxTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  /* identical to box height, or 180% */

  display: flex;
  align-items: center;

  color: white;
}

.boxButton {
  align-self: flex-end;
}

.skChase {
  width: 40px;
  height: 40px;
  position: absolute;
  animation: sk-chase 2.5s infinite linear both;
  left: 50%;
  top: 50%;
}

.skChaseDot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: skChaseDot 2s infinite ease-in-out both;
}

.skChaseDot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: black;
  border-radius: 100%;
  animation: skChaseDot-before 2s infinite ease-in-out both;
}

.skChaseDot:nth-child(1) {
  animation-delay: -1.1s;
}
.skChaseDot:nth-child(2) {
  animation-delay: -1s;
}
.skChaseDot:nth-child(3) {
  animation-delay: -0.9s;
}
.skChaseDot:nth-child(4) {
  animation-delay: -0.8s;
}
.skChaseDot:nth-child(5) {
  animation-delay: -0.7s;
}
.skChaseDot:nth-child(6) {
  animation-delay: -0.6s;
}
.skChaseDot:nth-child(1):before {
  animation-delay: -1.1s;
}
.skChaseDot:nth-child(2):before {
  animation-delay: -1s;
}
.skChaseDot:nth-child(3):before {
  animation-delay: -0.9s;
}
.skChaseDot:nth-child(4):before {
  animation-delay: -0.8s;
}
.skChaseDot:nth-child(5):before {
  animation-delay: -0.7s;
}
.skChaseDot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes skChaseDot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes skChaseDot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}

.unlockbutton {
  height: 30px;
  border-radius: 5px;

  transition: color 0.7s, background-color, border 0.7s;
  align-items: center;
  cursor: pointer;
}

.largetext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.smalltext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
}

.button_0 {
  color: white;
  background: transparent;
  border: 3px solid white;

  align-items: center;
  text-align: center;
}

.button_0:hover {
  color: black;
  background: white;
  /* border: 3px solid black; */
}

.password {
  width: 90% !important;
  height: 30px;
  margin-top: 5px;
  background: #e9e9ea;
  /* margin-right: 5px; */
  border-radius: 11px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
}
