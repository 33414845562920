.card {
  box-sizing: border-box;

  position: absolute;
  width: 46%;
  height: 702px;
  /* left: 87px; */
  /* top: 390px; */
  right: 0;
  top: 0;

  background: #121212;
  border: 1px solid #2c2c2c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  display: flex;
  flex-direction: row;
}

.bar {
  width: 35%;
  margin-top: 20%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.radar {
  margin-left: 2%;
  width: 68%;
  margin-top: 20%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.img {
  width: 100%;
  margin-top: 8%;
}

.h80 {
  height: 90%;
}

.chartName {
  margin-left: auto;
  margin-right: auto;
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  /* identical to box height, or 225% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}

.title {
  position: absolute;
  margin-top: 16px;
  margin-left: 26px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  /* identical to box height, or 180% */

  display: flex;
  align-items: center;

  color: #ffffff;
}

.hint {
  position: absolute;
  margin-top: 41px;
  margin-left: 26px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  /* identical to box height, or 225% */

  display: flex;
  align-items: center;

  color: #ea4c6f;
}

.DecoLine {
  position: absolute;
  margin-top: 85px;
  margin-left: 26px;
}

.legend_1 {
  position: absolute;
  margin-left: 83px;
  margin-top: 102px;

  width: 32px;
  height: 10px;

  background: rgba(234, 76, 111, 0.2);
  border: 1px solid #ea4c6f;
}

.legend_2 {
  position: absolute;
  margin-left: 187px;
  margin-top: 102px;

  width: 32px;
  height: 10px;

  background: rgba(36, 83, 255, 0.2);
  border: 1px solid #2453ff;
}

.legend_1_text {
  position: absolute;
  margin-left: 26px;
  margin-top: 102px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;
  /* identical to box height, or 83% */

  display: flex;
  align-items: center;

  color: #888888;
}

.legend_2_text {
  position: absolute;
  margin-left: 134px;
  margin-top: 102px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;
  /* identical to box height, or 83% */

  display: flex;
  align-items: center;

  color: #888888;
}
