.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* background: #000000; */

  height: 100%;
  width: 100%;
  position: absolute;
}

.content {
  position: absolute;
  height: 100%;
  max-width: 1440px;
  max-height: 1024px;
  aspect-ratio: 1440 / 1024;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.button {
  position: absolute;
  right: 64px;
  bottom: 76px;

  min-width: 200px;
  min-height: 60px;
}
